import { ReactComponent as Trophy } from '../../../assets/trophy.svg';
import { ReactComponent as User } from '../../../assets/user.svg';

import { useEffect, useState } from 'react'

import { pollPlayer, getRemainingPlayers, getWinner } from '../../../utils/requests'


import './TournamentCard.css';
import { useInterval } from '../../../utils/interval';
import { useNavigate } from 'react-router';

// TODO: get num Wins and num Players Remaining

const numWins = 0;
const numPlayersRemaining = 12345;
const TournamentCard = (props: any) => {
  const navigate = useNavigate();
  const [player, setPlayer] = useState<any>(undefined)
  const [wins, setWins] = useState(0)
  const [remainingPlayers, setRemainingPlayers] = useState(0)

  useEffect(() => {
    async function pollPlayerStats() {
      const playerStats = await pollPlayer(`${localStorage.getItem('userId')}`)
      if (playerStats) {
        setPlayer(playerStats.player)
        localStorage.setItem("wins", playerStats.player.wins)
        localStorage.setItem("contention", playerStats.player.in_contention)
        localStorage.setItem("avatar", playerStats.player.avatar)
        setWins(playerStats.player.wins)
      }
    }

    pollPlayerStats()

  }, [])

  useInterval(() => {
    async function numRemainingPlayers() {
      const numPlayers = await getRemainingPlayers()
      if (numPlayers.numRemainingPlayers > 0) {
        localStorage.setItem("remaining", numPlayers.numRemainingPlayers)
        setRemainingPlayers(numPlayers.numRemainingPlayers)
      }
    }
    numRemainingPlayers()
  }, 10000)

  useEffect(() => {
    async function numRemainingPlayers() {
      const numPlayers = await getRemainingPlayers()
      if (numPlayers.numRemainingPlayers > 0) {
        localStorage.setItem("remaining", numPlayers.numRemainingPlayers)
        setRemainingPlayers(numPlayers.numRemainingPlayers)
      }

      if (numPlayers.numRemainingPlayers === 1) {
        let userId = localStorage.getItem("userId")
        const response = await getWinner()
        if (response) {
          if (response.response) {
            const winnerId = response.response.Items[0].id
            console.log(userId)
            console.log(winnerId)
            if (userId === winnerId) {
              navigate("/winner")
            }
          }
        }

      }
    }
    numRemainingPlayers()
  }, [])


  return (
    <div className='tournament-plate'>
      <div className='info-set'>
        <Trophy className='filter-blue' />
        <span>&nbsp;&nbsp;{wins} Wins</span>
      </div>
      <div className='info-set'>
        <User className='filter-blue' />
        <span>&nbsp;&nbsp;{remainingPlayers} Players Remaining</span>
      </div>
    </div>
  );
};

export default TournamentCard;
