
import PlayerCard from '../ui/PlayerCard/PlayerCard';
import TournamentCard from '../ui/TournamentCard/TournamentCard';

import './Lobby.css';
import PlayworksLogo from '../../assets/playworks-logo.png'
import whiteLogo from '../../assets/tournament-logo.png';
import Loading from '../ui/loading/Loading';
import { useEffect, useState } from 'react';

import { checkMatchAvailability, enterMatchmaking, getFact, pollMatchStatus } from '../../utils/requests';
import { match } from 'assert';
import { useNavigate } from 'react-router-dom';
import { useInterval } from '../../utils/interval';
import NotInContention from '../ui/NotInContention/NotInContention';

const Lobby = (): JSX.Element => {
  const navigate = useNavigate();
  const [fact, setFact] = useState('')
  const [enterStatus, setenterStatus] = useState('Finding your next game')

  useEffect(() => {
    async function gettingFacts() {
      const fact = await getFact()
      setFact(fact)
    }

    gettingFacts()
  }, [enterStatus])

  const enter = async () => {
    setenterStatus("Finding your next game")
    const response = await enterMatchmaking(`${localStorage.getItem('userId')}`)
    if (response === "Matchmaking Entered") {
      setenterStatus("Waiting Opponent")
      const pollMatch = await pollMatchStatus(`${localStorage.getItem('userId')}`)
      if (pollMatch.match) {
        if (pollMatch.match.match_status === "Started") {

        }
        else if (pollMatch.match.player_1_id !== "" && pollMatch.match.player_2_id !== "") {
          setenterStatus("Loading Match")
          navigate(`/game/${pollMatch.match.id}`)
        }
      }
    } else {
      console.log("not entered match")
    }
  }

  useEffect(() => {
    localStorage.setItem('otherWins', '')
    localStorage.setItem('otherFirstName', '')
    localStorage.setItem('otherLastName', '')
    localStorage.setItem('otherUserId', '')
    enter()
  }, [])

  useInterval(async () => {
    if (enterStatus === "Waiting Opponent") {
      const pollMatch = await pollMatchStatus(`${localStorage.getItem('userId')}`)
      if (pollMatch) {
        if (pollMatch.match) {
          if (pollMatch.match === "deleted") {
            enter()
          }
          if (pollMatch.match.player_1_id && pollMatch.match.player_2_id) {
            setenterStatus("Loading Match")
            navigate(`/game/${pollMatch.match.id}`)
          }

        }
      }
    } else {
      console.log("not entered match")
    }
  }, 1000)

  return (
    <div className='lobby'>
      <NotInContention />
      <header className='header'>
        <PlayerCard invisible={false} />
        <div className='img-container'>
          <img src={PlayworksLogo} alt='Playworks Logo' />
          <img className='white-logo' src={whiteLogo} alt="Playworks Logo" />
        </div>
        <PlayerCard invisible={true} /> {/* for spacing purposes */}
      </header>
      <div>
        <TournamentCard />
        <div className='match-title'>
          <div className='match-title-text'>{enterStatus}</div>
        </div>
      </div>

      <Loading isLoading={true} />
      <div className='match-disclaimer'>
        Sit tight, your game is about to start!
      </div>
      <div className='fun-fact'>
        <h1>Did you know?</h1>
        <p>
          {fact}
        </p>
      </div>
    </div>
  );
};

export default Lobby;
