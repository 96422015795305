import rock from '../../../assets/game_assets/rock.png';
import paper from '../../../assets/game_assets/paper.png';
import scissors from '../../../assets/game_assets/scissors.png';
// import rock from '../../../assets/game_assets/rock.svg';
// import paper from '../../../assets/game_assets/paper.svg';
// import scissors from '../../../assets/game_assets/scissors.svg';
import noneImg from '../../../assets/game_assets/none.svg';

import './Choices.css';

const Choices = ({ option, clicked }: any) => {
  const optionURL =
    option === 'Rock' ? rock
      : option === 'Paper' ? paper
        : option === 'Scissors' ? scissors
          : option === 'None' ? noneImg
            : option === 'Quit' ? noneImg
              : undefined;

  const optionName = option ? option : 'What will they choose??';

  let beat: string = '';
  let lose: string = '';
  if (option === 'Rock') {
    beat = "Scissors"
    lose = "Paper"
  } else if (option === 'Paper') {
    beat = "Rock"
    lose = "Scissors"
  } else if (option === 'Scissors') {
    beat = "Paper"
    lose = "Rock"
  } else {
    beat = "None"
    lose = "All"
  }
  return (
    <li
      className='game-selection-card'
      key={option}
      onClick={() => clicked(option)}>
      <img src={optionURL ? optionURL : noneImg} alt={optionName} />
      <p>{optionName}</p>
      {optionName !== 'What will they choose??' ?
        <div className='game-selection-option-sub'>
          <p>Beats {beat}</p>
          <p>Loses to {lose}</p>
        </div> : <></>
      }
    </li>
  );
};

export default Choices;
