import '../ui/queue/Queue.css'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useInterval } from '../../utils/interval'
import {
    getUser,
    makeMove,
    notifyWaiting,
    matchWon,
    pollPlayer,
    checkMatchStatus,
    checkMatchState,
    pollMatchStatus,
} from '../../utils/requests'

import Overlay from '../ui/loading/Overlay'
import MatchCard from '../ui/MatchCard/MatchCard'
import GameLoading from './GameLoading'
import GameMakeSelection from './GameMakeSelection'
import GameShowSelection from './GameShowSelection'
import GameShowResults from './GameShowResults'
import RoundCountDown from './GameUtils/RoundCountDown'
import ExitButton from './GameUtils/ExitButton'
import { error } from 'console'

const GameController = () => {

    const { id } = useParams()
    const navigate = useNavigate();

    // Pre Game Info
    const [timer, setTimer] = useState(3)
    const [match, setMatch] = useState<any>(undefined)
    const [playerNumber, setPlayerNumber] = useState('')
    const [otherPlayerNumber, setOtherPlayerNumber] = useState('')
    const [otherPlayerId, setOtherPlayerId] = useState('')
    const [otherPlayerLastId, setOtherPlayerLastId] = useState('')
    const [wins, setWins] = useState(0)
    const [otherWins, setOtherWins] = useState(0)
    const [otherAvatar, setOtherAvatar] = useState<any>(undefined)

    // Game Info
    const [selectedMove, setSelectedMove] = useState('')
    const [updatedMove, setupdatedMove] = useState('')
    const [moveReceived, setMoveReceived] = useState('')
    const [loading, setLoading] = useState(false)
    const [round, setRound] = useState(0)
    const [roundDate, setRoundDate] = useState(new Date())
    const [gameTimer, setGameTimer] = useState(30);

    // Controllers

    const [gameStart, startGame] = useState(false)
    const [waiting, setWaiting] = useState(false)
    const [gameDecided, setGameDecided] = useState(false)
    const [matchDecided, setMatchDecided] = useState(false)

    const playerName = `${localStorage.getItem('firstName')} ${localStorage
        .getItem('lastName')
        ?.charAt(0)}.`;
    const opponentName = `${localStorage.getItem('otherFirstName')} ${localStorage
        .getItem('otherLastName')
        ?.charAt(0)}.`;

    const countDownGame = async (startingValue: number, setNumber: (value: number) => void, startGame: (value: boolean) => void) => {
        if (startingValue >= 1) {
            setTimeout(() => {
                setNumber(startingValue - 1)
                if (startingValue - 1 === 0) startGame(true)
                countDownGame(startingValue - 1, setNumber, startGame)
            }, 1000)
        }
        return
    }

    const updateMove = async (option: string) => {
        setLoading(true)
        await makeMove(match.id, `${localStorage.getItem('userId')}`, option)
        setSelectedMove(selectedMove)
        setLoading(false)
    };

    const getOtherUser = async (otherId: string) => {
        const otherUser = await getUser(otherId)
        if (otherUser) {
            localStorage.setItem('otherWins', otherUser.wins)
            localStorage.setItem('otherFirstName', otherUser.first_name)
            localStorage.setItem('otherLastName', otherUser.last_name[0])
            localStorage.setItem('otherUserId', otherPlayerId)
            setOtherAvatar(otherUser.avatar)
        }
    }

    const createTimer = () => {
        if (match) {
            let timeStamp: any = new Date()
            let roundTimer: any = new Date(match.time_stamp)

            let time = 35 - new Date(timeStamp - roundTimer).getSeconds()
            let timeLogic = timeStamp - roundTimer
            console.log(timeStamp - roundTimer)
            // Run when countdown hits 0 and send players move to none
            if (!matchDecided || !gameDecided) {
                if (timeLogic >= 35000) {
                    if (timeLogic >= 37000) {
                        console.log("2 seconds")
                        // if (selectedMove === "" || selectedMove === "None") updateMove("None");
                    }
                    time = 0;
                }
            }
            setGameTimer(time)
        } else {
            setGameTimer(30)
        }
    }

    const updatePlayersInformation = (playerNumber: string) => {
        setRound(match.game_count)
        if (playerNumber === 'Player 1') {
            setWins(match.player_1_wins)
            setOtherWins(match.player_2_wins)
            setSelectedMove(match.player_1_last_move)
            setupdatedMove(match.player_1_last_move)
            setMoveReceived(match.player_2_last_move)
        } else {
            setWins(match.player_2_wins)
            setOtherWins(match.player_1_wins)
            setSelectedMove(match.player_2_last_move)
            setupdatedMove(match.player_2_last_move)
            setMoveReceived(match.player_1_last_move)
        }
    }

    const runOnce = () => {
        const getOtherPlayerId = match.player_1_id === `${localStorage.getItem('userId')}` ? match.player_2_id : match.player_1_id
        const playerNumber = match.player_1_id === `${localStorage.getItem('userId')}` ? 'Player 1' : 'Player 2'
        setPlayerNumber(playerNumber)
        setOtherPlayerNumber(playerNumber === 'Player 1' ? 'Player 2' : 'Player 1')
        setOtherPlayerId(getOtherPlayerId)
        getOtherUser(getOtherPlayerId)
    }

    useInterval(async () => {
        createTimer()
    }, 1000)

    useEffect(() => {
        countDownGame(timer, setTimer, startGame)
        if(match) {
            runOnce()
        }
    }, [])

    // function runs every half of second and query the backend to see if the match
    useInterval(async () => {
        const pollMatch = await pollMatchStatus(`${localStorage.getItem('userId')}`)
        console.log(pollMatch)
        if (pollMatch) {
            if (pollMatch.match) {
                setMatch(pollMatch.match)
            }
        }
        else {
            navigate('/lobby')
        }
    }, 1000)

    // This function will run everytime match changes, and updates players status.
    useEffect(() => {
        if (match && !matchDecided) {
            const matchState = match.match_status;
            const roundDecided = matchState === `Player 1 Wins Game` || matchState === `Player 2 Wins Game` || matchState === 'Game Tie'
            const matchEnded = match.match_status === `Player 1 Wins Match` || match.match_status === `Player 2 Wins Match`
            updatePlayersInformation(playerNumber)
            runOnce()

            if (roundDecided) {
                setTimeout(() => {
                    setLoading(false)
                    setGameDecided(true)
                    notifyWaiting(match.id, `${localStorage.getItem('userId')}`)
                    if (matchState === 'Game Tie') setRoundDate(match.time_stamp);
                }, 1000)
            }
            else if (matchEnded) {
                setGameDecided(false)
                setMatchDecided(true)
            }
            else if (matchState === 'Waiting') {
                setGameDecided(false)
                setWaiting(true)
                setLoading(false)
            }
            else if (matchState === `${otherPlayerNumber} Waiting`) {
                setLoading(true)
                notifyWaiting(match.id, `${localStorage.getItem('userId')}`)
            }
            else if (matchState === `${playerNumber} Waiting`) {
                setLoading(true)
            }
            if (moveReceived !== ""
                && selectedMove !== ""
                && !roundDecided
                && matchState !== `${otherPlayerNumber} Waiting`
                && matchState === `${playerNumber} Waiting`) {
                if (playerNumber === "Player 1") {
                    console.warn("There is a bug, we may resolve now")
                    // checkBugResult().then(resp => {

                    // }, error => {
                    //     navigate("/lobby")
                    // })
                } else {
                    console.warn("There is a bug with the other player, please wait a bit")
                }
            }
        }
    }, [match])

    useEffect(() => {
        if (match) {
            let playerNumber = match.player_1_id === `${localStorage.getItem('userId')}` ? 'Player 1 Wins Match' : 'Player 2 Wins Match'
            if (matchDecided && match.match_status === playerNumber) {
                let winner: any
                let loser: any

                if (match.player_1_wins === 3) {
                    winner = match.player_1_id
                    loser = match.player_2_id
                } else {
                    winner = match.player_2_id
                    loser = match.player_1_id
                }
                matchWon(winner, loser, match.id).then(response => {
                    navigate(`/gameResult/${id}/w`)
                })
            } else {
                if (matchDecided) {
                    navigate(`/gameResult/${id}/l`)
                }
            }
        }
    }, [matchDecided])

    return (
        <div className='queue' style={{ backgroundColor: match ? ((match.match_status === `${playerNumber} Wins Game` || match.match_status === `${playerNumber} Wins Match`) ? '#007e41' : (match.match_status === 'Game Tie' ? '#953E00' : ((match.match_status === `${otherPlayerNumber} Wins Match` || match.match_status === `${otherPlayerNumber} Wins Game`) ? '#a52d33' : '#673B90'))) : '#054f95' }}>
            <Overlay isLoading={loading} />
            <div className='game-start' >
                <div className='game-loading' style={{ opacity: gameStart ? '0' : (matchDecided ? '0' : '1'), display: gameStart ? 'none' : (matchDecided ? 'none' : 'unset') }}>
                    <GameLoading timer={timer} />
                </div>
                <div className='game-started' style={{ opacity: (gameStart || (!gameStart && matchDecided)) ? '1' : '0' }}>
                    <div className='game-counter'>Round {round + 1}</div>
                    <div className='game-score'>
                        <MatchCard cardFor={playerName} avatar="user" winCount={wins} />
                        <div className='user-vs-text'>VS</div>
                        <MatchCard cardFor={opponentName} avatar={otherAvatar} winCount={otherWins} />
                    </div>
                    {
                        (!gameDecided && !matchDecided) ?
                            updatedMove === '' ?
                                <GameMakeSelection setSelectedMove={updateMove} />
                                :
                                <GameShowSelection playerSelection={selectedMove} opponentName={opponentName + " pick"} opponentSelection={moveReceived} />
                            : <></>
                    }
                    {
                        !gameDecided && !matchDecided && <RoundCountDown gameTimer={gameTimer} />
                    }
                    {gameDecided && match.match_status === 'Game Tie' &&
                        <GameShowResults selectedMove={selectedMove} resultText={'tie'} />
                    }
                    {gameDecided && match.match_status === `${playerNumber} Wins Game` &&
                        <GameShowResults selectedMove={selectedMove} resultText={'won'} />
                    }
                    {gameDecided && match.match_status === `${otherPlayerNumber} Wins Game` &&
                        <GameShowResults selectedMove={moveReceived} resultText={'lost'} />
                    }
                </div>
            </div>
            <ExitButton id={id} />
        </div>
    )
}

export default GameController