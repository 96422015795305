import Choices from './Choices/Choices';
import './GameShowResults.css';


type GameShowResultsProps = {
  selectedMove: string;
  resultText: string;
}

const GameShowResults = ({ selectedMove, resultText }: GameShowResultsProps) => {
  return (
    <div className='game-selection'>
      {resultText === "lost" ?
        <div className='game-selection-headline'>
          Nice try, better luck next time!
        </div>:
        <div className='game-selection-headline'>
          You {resultText} this round!
        </div>
      }
      <div
        className='game-selection-cards'
        style={{ justifyContent: 'center' }}>
        <Choices
          className='game-selection-card'
          option={selectedMove}
          clicked={() => {
            return
          }}
        />
      </div>
    </div>
  )
}

export default GameShowResults