import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './Timer.css';

interface timeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

/*
 * This needs to be retrieved from the server
 * so that everyone has the same time regardless
 * of time zones or try this type of string '29 Jun 2022 16:00 UTC-7'
 */


const Timer = (): JSX.Element => {
  const navigate = useNavigate();
  const tournamentStartTimeUTC = new Date('29 Jun 2022 19:00 UTC-4');
  const calculateTimeLeft = () => {
    let difference = +new Date(tournamentStartTimeUTC) - +new Date();

    let TWO_HOURS_IN_MILISSECONDS = 7200000
    if (difference < TWO_HOURS_IN_MILISSECONDS) navigate("/login")

    let timeLeft: any = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents: Array<JSX.Element> = [];

  // fixTime should add a 0 to numbers below 10 to keep countdown width
  const fixTime = (n: number) => n < 10 ? "0" + n : n;

  Object.keys(timeLeft).map((interval: string) => {
    timerComponents.push(
      <div className='numbercard' key={interval}>
        <h2 className='number'>{fixTime(timeLeft[interval as keyof typeof timeLeft])}</h2>
        <span>{interval}</span>
      </div>
    );
  });

  return (
    <div className='timer'>
      <h3 style={{ color: 'rgba(255, 255, 255, 0.87)' }}>
        Tournament starts in
      </h3>
      <div className='numbercard-container'>{timerComponents}</div>
    </div>
  );
};

export default Timer;
