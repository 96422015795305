import axios from 'axios'

export const pollMatchStatus = async (playerId: string) => {
    try {
        const getMatchStatus = await axios.get(`${process.env.REACT_APP_API_URL}/checkMatch?id=${playerId}`)
        return getMatchStatus.data.message
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const checkMatchState = async (playerId: string) => {
    try {
        const getMatchStatus = await axios.get(`${process.env.REACT_APP_API_URL}/checkMatchById?id=${playerId}`)
        return getMatchStatus.data.message
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const checkMatchAvailability = async (playerId: string) => {
    try {
        const getMatchStatus = await axios.get(`${process.env.REACT_APP_API_URL}/checkMatchAvailability?id=${playerId}`)
        return getMatchStatus.data.message
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const enterMatchmaking = async (id: string) => {
    try {
        const enter = await axios.get(`${process.env.REACT_APP_API_URL}/enter?id=${id}`)
        return enter.data.message
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const notifyWaiting = async (matchId: string, playerId: string) => {
    try {
        const move = await axios.post(`${process.env.REACT_APP_API_URL}/notify`, {
            matchId: matchId,
            playerId: playerId,
        })
        return move.data
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const makeMove = async (matchId: string, playerId: string, moveMade: string) => {
    try {
        const move = await axios.post(`${process.env.REACT_APP_API_URL}/move`, {
            matchId: matchId,
            playerId: playerId,
            moveMade: moveMade
        })
        return move.data
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const signIn = async (email: string, password: string) => {
    try {
        const signIn = await axios.post(`${process.env.REACT_APP_API_URL}/signin`, {
            email: email,
            password: password
        })
        return signIn.data.message
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const getAvatars = async () => {
    const avatars = await axios.get(`${process.env.REACT_APP_API_URL}/avatars`)
    return avatars.data
}

export const getUser = async (userId: string) => {
    try {
        const user = await axios.get(`${process.env.REACT_APP_API_URL}/users?id=${userId}`)
        return user.data.message
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const matchWon = async (playerId: any, otherPlayerId: any, matchId: string) => {
    try {
        const user = await axios.post(`${process.env.REACT_APP_API_URL}/matchWon?id=${playerId}&id=${otherPlayerId}&id=${matchId}`)
        return user.data.message

    } catch(err: any) {
        return err.response.data.message.message
// integrate ui to backend
    }
}

export const chooseAvatar = async (playerId?: string, avatar?: string) => {
    try {
        const chosenAvatar = await axios.post(`${process.env.REACT_APP_API_URL}/chooseAvatar?id=${playerId}&id=${avatar}`)
        return chosenAvatar.data.message
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const pollPlayer = async (playerId: string) => {
    try {
        const getPlayer = await axios.get(`${process.env.REACT_APP_API_URL}/getPlayerInfo?id=${playerId}`)
        return getPlayer.data.message
    } catch(err: any) {
        return err.response.data.message.message
    }
}

export const getRemainingPlayers = async () => {
    try {
        const getNumRemainingPlayers = await axios.get(`${process.env.REACT_APP_API_URL}/getRemainingPlayers`)
        return getNumRemainingPlayers.data.message
    } catch(err: any) {
        return err.response.data.message.message
    }
}

export const getFact = async () => {
    try {
        const funFact = await axios.get(`${process.env.REACT_APP_API_URL}/getfunfact`)
        return funFact.data.message
    } catch(err:any) {
        return err.response.data.message.message
    }
}

export const getLeaderboard = async () => {
    try {
        const lb = await axios.get(`${process.env.REACT_APP_API_URL}/getRanking`)
        return lb.data.message
    } catch(err:any) {
        return err.response.data.message.message
    }
}

export const checkMatchStatus = async (matchId: string, playerId: string, moveMade: string) => {
    try {
        const move = await axios.post(`${process.env.REACT_APP_API_URL}/playerCheckResult`, {
            matchId: matchId,
            playerId: playerId,
            moveMade: moveMade
        })
        return move.data
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const setWinner = async (playerId: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/setWinner`, {
            playerId: playerId
        })
        return response.data
    }
    catch(err: any) {
        return err.response.data.message.message
    }
}

export const getWinner = async () => {
    try {
        const lb = await axios.get(`${process.env.REACT_APP_API_URL}/getWinner`)
        return lb.data.message
    } catch(err:any) {
        return err.response.data.message.message
    }
}

