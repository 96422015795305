import { useState, useContext, useEffect } from 'react';
import { avatarsContext, UserContext } from '../../App';
import { useNavigate } from 'react-router-dom';

import Button from '../ui/Button/Button';
import Card from '../ui/Card/Card';
import Modal from '../ui/Modal/Modal';
import { chooseAvatar, pollPlayer } from '../../utils/requests';
import AvatarChoices from './AvatarChoices';

import './ChooseAvatar.css';


const ChooseAvatar = (): JSX.Element => {
  const navigate = useNavigate();
  const avatars = useContext(avatarsContext);
  const user = useContext(UserContext);

  const [isOpen, setOpen] = useState(false);
  const [userAvatar, setUserAvatar] = useState("");


  useEffect(() => {
    async function pollPlayerStats() {
        const playerStats = await pollPlayer(`${localStorage.getItem('userId')}`)
        if (playerStats.player.avatar) {
          setUserAvatar(playerStats.player.avatar)
        }
    }

    pollPlayerStats()

  }, [])

  const userIcon = (
    <img
      className='avatar-icon'
      src={user.avatar}
      onClick={() => setOpen(true)}
    />
  );

 
  const closeModal = () => {
    setOpen(!isOpen);
  };


  // aync function
  // TODO: replace function body to send user to tournament page instead
  const enterNewTournament = async () => {
    await chooseAvatar(localStorage.getItem('userId')?.toString(), user.avatar )
    navigate("/how-to")
  };

  return (
    <div className='choose-avatar'>
      <Modal isVisible={isOpen} clicked={closeModal}>
        <Card logo=''>
          <h3 className='choose-avatar-header'>Choose your avatar!!</h3>
          <AvatarChoices />
        </Card>
      </Modal>

      <Card logo='white'>
        <h3>Choose an avatar to use for the tournament!</h3>
        {userIcon}
        <a className='choose-avatar-btn' onClick={() => setOpen(true)} style={{marginTop: '20px'}}>Choose another avatar!</a>
      </Card>
      <Button
        text="I'm ready to enter the tournament!"
        clicked={enterNewTournament}
      />
    </div>
  );
};

export default ChooseAvatar;
