import { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../App';
import {pollPlayer} from '../../../utils/requests'


import './PlayerCard.css';

import one from '../../../assets/avatars/1.png'
import two from '../../../assets/avatars/2.png'
import three from '../../../assets/avatars/3.png'
import four from '../../../assets/avatars/4.png'
import five from '../../../assets/avatars/5.png'
import six from '../../../assets/avatars/6.png'
import seven from '../../../assets/avatars/7.png'
import eight from '../../../assets/avatars/8.png'
import nine from '../../../assets/avatars/9.png'
import ten from '../../../assets/avatars/10.png'
import eleven from '../../../assets/avatars/11.png'
import twelve from '../../../assets/avatars/12.png'
import thirteen from '../../../assets/avatars/13.png'
import fourteen from '../../../assets/avatars/14.png'
import fifteen from '../../../assets/avatars/15.png'
import sixteen from '../../../assets/avatars/16.png'
import seventeen from '../../../assets/avatars/17.png'
import eighteen from '../../../assets/avatars/18.png'

const avatars: any = {
  '/static/media/1.2a93ee6ef2bd213a8dcd.png': one,
  '/static/media/2.04c3ef2a11c4aeb2e545.png': two,
  '/static/media/3.834fad9d8626768e8688.png': three,
  '/static/media/4.28c618fbfbbfbd7735f0.png': four,
  '/static/media/5.18e0cc3cd03edab147e4.png': five,
  '/static/media/6.af2c893ae0bc9503767f.png': six,
  '/static/media/7.67022a2cf06d01cbe4f0.png': seven,
  '/static/media/8.2bf535a004ac11e95139.png': eight,
  '/static/media/9.c858505c495030176c10.png': nine,
  '/static/media/10.c280082cca923b0659ab.png': ten,
  '/static/media/11.e208e8bc2d8af75f0954.png': eleven,
  '/static/media/12.00e2e16ed9a2d7c83d0d.png': twelve,
  '/static/media/13.f2fd87b5d85e8cdf4109.png': thirteen,
  '/static/media/14.2f2d4d4217ebfa878bbd.png': fourteen,
  '/static/media/15.fd54fb625e903835bee6.png': fifteen,
  '/static/media/16.4d5b3a8dd4c7fe9ec40d.png': sixteen,
  '/static/media/17.94ddeecc7ac5e315e3b0.png': seventeen,
  '/static/media/18.e6690c77f02cf7133392.png': eighteen
}

const PlayerCard = ({invisible}: any) => {

  const [avatar, setAvatar] = useState<any>(undefined)

  const user = useContext(UserContext);
  const userName = `${localStorage.getItem(
            'firstName'
          )} ${localStorage.getItem('lastName')?.charAt(0)}.`

  let classNamePlate = 'name-plate';
  let classUserIcon = 'user-icon';
  let classColor = {color: 'white'};

  if (invisible) {
    classNamePlate += ' invisible';
    classUserIcon += ' invisible';
    classColor.color += ' invisbile';
  }

  

  useEffect(() => {
    async function pollPlayerStats() {
        const playerStats = await pollPlayer(`${localStorage.getItem('userId')}`)
        if (playerStats.player.avatar) {
            setAvatar(playerStats.player.avatar)
        }
    }

    pollPlayerStats()

  }, [])

  return (
    <div className={classNamePlate}>
      <img className={classUserIcon} src={avatar} alt="User Avatar"/>
      <p style={classColor}>{userName}</p>
    </div>
  );
};

export default PlayerCard;
