import Choices from './Choices/Choices';
import './GameShowSelection.css';

type GameShowSelectionProps = {
  playerSelection: string;
  opponentName: string;
  opponentSelection: string;
};

const GameShowSelection = ({
  playerSelection,
  opponentName,
  opponentSelection,
}: GameShowSelectionProps) => {
  return (
    <div className='game-selection'>
      <div className='game-selection-headline'>Waiting for opponent</div>
      <div
        className='game-selection-cards'
        style={{ justifyContent: 'space-around' }}>
        <div className='game-selection-user-card'>
          <div className='game-selection-title'>Your pick</div>

          <Choices
            option={playerSelection}
            clicked={() => console.log('clicked playerSelection')}
          />
        </div>
        <div className='game-selection-user-card'>
          <div className='game-selection-title'>{opponentName}</div>
          <Choices
            option={opponentSelection}
            clicked={() => console.log('clicked opponentSelection')}
          />
        </div>
      </div>
    </div>
  );
};

export default GameShowSelection;
