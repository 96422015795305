import Button from '../ui/Button/Button';
import Card from '../ui/Card/Card';
import Timer from '../ui/Timer/Timer';

import './Countdown.css';


const Countdown = (): JSX.Element => {
  const openRegistrationWindow = (): void => {
    window.open(
      'https://www.eventbrite.com/e/playworks-rock-paper-scissors-tournament-2022-tickets-231038170387'
    );
  };

  return (
    <div className='countdown'>
      <Timer />
      <Card logo='color'>
        <p className='subtitle'>
          Have what it takes to be the winner of the largest Rock Paper Scissors
          game of all time?
        </p>
        <p className='body1'>
          Join Playworks community members–kids and adults–from our communities
          throughout the country for both the joy of the game as well as the
          chance to break a world record. To participate in the tournament,
          register your child with the link below:
        </p>
        <Button text='Register now!' clicked={openRegistrationWindow}></Button>
      </Card>
    </div>
  );
};

export default Countdown;
