
import { ReactComponent as Crown } from '../../assets/crown.svg'
import { useNavigate } from 'react-router-dom';
import Modal from '../../ui/Modal/Modal';
import Card from '../../ui/Card/Card';
import { useState } from 'react';
import { makeMove } from '../../../utils/requests';


const ExitButton = ({
    id,
}: any) => {

    const [isOpen, setOpen] = useState(false);

    const quit = async () => {
        await makeMove(id, `${localStorage.getItem('userId')}`, "Quit")
        navigate("/result")
    }

    const closeModal = () => {
        setOpen(!isOpen);
    };
    const navigate = useNavigate();
    return (
        <div>
            <div className='exit-btn' onClick={() => setOpen(true)}>
                <button>X</button>
            </div>
            <Modal isVisible={isOpen} clicked={closeModal}>
                <Card logo=''>
                    <p>Are you sure you want to quit?</p>
                    <div className="btn-div">
                        <button className='quit-btn' onClick={quit}>Yes</button>
                        <button className='cancel-btn' onClick={closeModal}>Cancel</button>
                    </div>
                </Card>
            </Modal>
        </div>
    );
};

export default ExitButton;
