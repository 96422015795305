import './Button.css';

interface ButtonProps {
  text: string;
  color?: string;
  clicked: () => void;
}

export const Button = ({ text, clicked, color="#49a8e1" }: ButtonProps): JSX.Element => {
  return (
    <button style={{backgroundColor: color}} className='btn btn-text' onClick={() => clicked()}>
      {text}
    </button>
  );
};

export default Button;