import { useState, useEffect } from 'react';
import './Leaderboard.css';
import { getLeaderboard } from '../../../utils/requests'
import { ReactComponent as Trophy } from '../../../assets/trophy.svg'
import Card from '../Card/Card';
import { useNavigate } from 'react-router-dom';
import Partners from '../Partners/Partners';

const Leaderboard = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>(undefined)
    const [start, setStart] = useState<boolean>(false)
    const [userPosition, setUserPosition] = useState<number>(0)
    const [totalPlayers, setTotalPlayers] = useState<number>(0)

    const updateTable = async () => {
        let dataR = await getLeaderboard()
        let firstName = localStorage.getItem("firstName")
        let lastName: any = localStorage.getItem("lastName")
        let fullName = firstName + " " + lastName[0]
        const index = dataR.rank.findIndex((object: any) => {
            return object.name === fullName;
        });
        setUserPosition(index + 1)
        setTotalPlayers(dataR.rank.length)
        setData(dataR.rank)
        setStart(true)
    }

    useEffect(() => {
        updateTable()
    }, [])
    useEffect(() => {
        updateTable()
    }, [start])

    return (
        <Card logo='white'>
            <Partners />
            <div className='user-leaderboard'>
                <Trophy className='trophy' />
                <div className="texts">
                    <h1>Leaderboard</h1>
                    <p>You placed {userPosition} out of {totalPlayers}</p>
                </div>
                <table>
                    <thead></thead>
                    <tbody>
                        {start ? data.map((item: any, index: number) =>
                            index + 1 === userPosition ?
                                <tr key={index}>
                                    <td className='in active'>#{index + 1}</td>
                                    <td className='na active'>{item.avatar ? <img className="user-icon" src={item.avatar} alt="User Avatar" /> : <></>}{item.name}</td>
                                    <td className='wi active'>{item.wins + " wins"}</td>
                                </tr> :
                                <tr key={index}>
                                    <td className='in'>#{index + 1}</td>
                                    <td className='na'>{item.avatar ? <img className="user-icon" src={item.avatar} alt="User Avatar" /> : <></>}{item.name}</td>
                                    <td className='wi'>{item.wins + " wins"}</td>
                                </tr>
                        ) : <tr>
                            <td>Tournament</td>
                            <td>not</td>
                            <td>finished</td>
                        </tr>}
                    </tbody>
                    {/* <tfoot></tfoot> */}
                </table>
            </div>
            <div className='go-to-button' onClick={() => navigate("/lobby")}>
                Play for fun!
            </div>
        </Card>
    );
};

export default Leaderboard;
