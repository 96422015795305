import { useState, useEffect } from 'react';
import Button from '../ui/Button/Button';
import Card from '../ui/Card/Card';
import { useNavigate } from 'react-router-dom';


import RockWonGif from '../../assets/how-to/RockWon.mp4'
import ScissorsWonGif from '../../assets/how-to/ScissorsWon.mp4'
import PaperWonGif from '../../assets/how-to/PaperWon.mp4'
import PlayCasualGif from '../../assets/how-to/PlayCasual.mp4'
import RPSImage from '../../assets/how-to/RPSChoices.png'



import './HowTo.css';

const HowTo = () => {

  const navigate = useNavigate();

  const pageOne = (
    <>
      <img className='asset-placeholder' alt='RPS Choices' src={RPSImage}/>
      <p className='body1'>
        You will be competing in a tournament style game. If you win your round,
        you will advance to the next one. If you lose, you’re out of the tournament but can continue playing for fun. Each match, 
        you will be given 30 seconds to choose your move. Win all the
        rounds and you will be crowned the winner!
        <br></br>
        For additional instructions on How To Play Click <a href='https://www.playworks.org/game-library/ro-sham-bo-or-rock-paper-scissors/'>Here!</a>
      </p>
      <Button color='#446ccf' text='When you choose Rock...' clicked={() => {
        setpageNumber(2)
        setPage(pageTwo)
      }} />
    </>
  );

  // #446ccf -blue/purple #007fff - dark/blue (buttons)
  const pageTwo = (
    <>
      <p className='body1'>
        Rock beats Scissors but loses to Paper
      </p>
      <Button color='#d74949' text='Back' clicked={() => {
        setpageNumber(1)
        setPage(pageOne)
      }} />
      <Button color='#446ccf' text='When you choose Paper...' clicked={() => {
        setpageNumber(3)
        setPage(pageThree)
      }} />
    </>
  );

  const pageThree = (
    <>
      <p className='body1'>
        Paper beats Rock but loses to Scissors
      </p>
      <Button color='#d74949' text='Back' clicked={() => {
        setpageNumber(2)
        setPage(pageTwo)
      }} />
      <Button color='#446ccf' text='When you choose Scissors...' clicked={() => {
        setpageNumber(4)
        setPage(pageFour)
      }} />
    </>
  );

  const pageFour = (
    <>
      <p className='body1'>
        Scissors beats Paper but loses to Rock
      </p>
      <Button color='#d74949' text='Back' clicked={() => {
        setpageNumber(3)
        setPage(pageThree)
      }} />
      <Button color='#446ccf' text='When you lose...' clicked={() => {
        setpageNumber(5)
        setPage(pageFive)
      }} />
    </>
  );

  const pageFive = (
    <>
      <p className='body1'>
        When you lose, you are removed from the tournament but have the option to <b>Play For Fun</b> until the tournament ends.
      </p>
      <p className='body1'>Good luck!</p>
      <Button color='#d74949' text='Back' clicked={() => {
        setpageNumber(4)
        setPage(pageFour)
      }} />
      <Button
        text='Enter Tournament'
        clicked={() => navigate("/lobby")}
      />
    </>
  );

  const [page, setPage] = useState<JSX.Element>(pageOne);
  const [videoSource, setVideoSource] = useState<any>();
  const [pageNumber, setpageNumber] = useState<number>(1);

  useEffect(() => {
    if (pageNumber === 1) {
      setVideoSource("")
    } else if (pageNumber === 2) {
      setVideoSource(RockWonGif)
    } else if (pageNumber === 3) {
      setVideoSource(PaperWonGif)
    } else if (pageNumber === 4) {
      setVideoSource(ScissorsWonGif)
    } else if (pageNumber === 5) {
      setVideoSource(PlayCasualGif)
    } else {

    }

  }, [pageNumber])

  return (
    <Card logo='white'>
      <h3>How to play Rock Paper Scissors</h3>
      {pageNumber !== 1 ?
        <video src={videoSource} id="video" className='asset-placeholder' autoPlay loop muted />
        :
        <></>}
      {page}
    </Card>
  );
};

export default HowTo;
