import Choices from './Choices/Choices';
import './GameMakeSelection.css';

type GameMakeSelectionProps = {
  setSelectedMove: (option: string) => void;
};

const GameMakeSelection = ({ setSelectedMove }: GameMakeSelectionProps) => {
  return (
    <div className='game-selection'>
      <div className='game-selection-headline'>Make a selection</div>
      <div className='game-selection-cards'>
        <Choices
          className='game-selection-card'
          option='Rock'
          clicked={() => {
            setSelectedMove('Rock');
          }}
        />
        <Choices
          className='game-selection-card'
          option='Paper'
          clicked={() => {
            setSelectedMove('Paper');
          }}
        />
        <Choices
          className='game-selection-card'
          // optionURL={scissors}
          option='Scissors'
          clicked={() => {
            setSelectedMove('Scissors');
          }}
        />
      </div>
    </div>
  );
};

export default GameMakeSelection;
