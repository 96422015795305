import './GameLoading.css';

import PlayworksLogo from '../../assets/playworks-logo.png'
import whiteLogo from '../../assets/tournament-logo.png';

type GameLoadingProps = {
  timer: number;
};

const GameLoading = ({ timer }: GameLoadingProps) => {
  return (
    <div className='game'>
      <header className='header'>
        <div className='img-container'>
          <img src={PlayworksLogo} alt='Playworks Logo' />
          <img className='white-logo' src={whiteLogo} alt="Tournament Logo" />
        </div>
      </header>
      <div className='game-start'>
        <div className='game-loading'>
          <div className='game-start-countdown'>
            <div className='game-start-headline'>Your game is starting!</div>
            <div className='countdown-circle'>{timer}</div>
            <div className='countdown-disclaimer'>
              {timer === 3 && 'Ready...'}
              {timer === 2 && 'Ready... set...'}
              {timer === 1 && 'Ready... set... go!'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameLoading;