import React from 'react'
import Loading from './Loading'
import './Loading.css'

interface Props {
    isLoading: boolean
}

const Overlay : React.FC<Props> = ({ isLoading }) => {
    return (
        isLoading ? 
        <div className='overlay'>
            <Loading isLoading={isLoading}/>
        </div> : null
    )
}

export default Overlay