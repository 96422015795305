import './GameEndMatch.css';
import { ReactComponent as Crown } from '../../assets/crown.svg'
import { useNavigate } from 'react-router-dom';
import { setWinner } from '../../utils/requests';

const GameWinner = () => {
    const navigate = useNavigate();

    const updateWinnerStatus = async () => {
        await setWinner(`${localStorage.getItem('userId')}`)
        navigate('/result')
    }
    return (
        <div className="queue" style={{ backgroundColor: '#007e41'}}>
            <div className="game-started" >
                <div className='game-selection' style={{ marginTop: '35px' }}>
                    <Crown />
                    <div className='game-selection-headline'>
                        You won the Tournament!
                    </div>
                    <div className='game-selection-subtitle'>
                        Congrats! You won Playworks Rock Paper Scissors Tournament!
                    </div>
                    <div style={{margin: '50px 0 auto'}} className='go-to-button' onClick={updateWinnerStatus}>
                        See the Results!
                    </div>
                </div >
            </div>

        </div>
    );
};

export default GameWinner;
