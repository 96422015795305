
import './NotInContention.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';



const NotInContention = () => {

    const navigate = useNavigate();
    const state = localStorage.getItem('contention')
    if (state === "true") {
        return (
        <div>
            <div className='not-contention-box'>
                <p>You are now: In the Tournament</p>
            </div>
        </div>)
    }

    return (
        <div>
            <div className='not-contention-box'>
                <p>You are now: Playing for Fun</p>
            </div>
            {/* <div className='not-contention-box2'>
                <button onClick={() => navigate('/result')}>See Results</button>
            </div> */}
        </div>
    );
};

export default NotInContention;
