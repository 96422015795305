
type RoundCountDownProps = {
    gameTimer: number;
};

const RoundCountDown = ({
    gameTimer,
}: RoundCountDownProps) => {

    return (
        <div className='countdown-circle game-timer'>{gameTimer}</div>
    );
};

export default RoundCountDown;
