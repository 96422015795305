import { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { getUser, signIn } from '../../utils/requests';

import Button from '../ui/Button/Button';
import Card from '../ui/Card/Card';
import Loading from '../ui/loading/Loading';
import Modal from '../ui/Modal/Modal';
import Partners from '../ui/Partners/Partners';

import './Login.css';


type FieldValidation = {
  blur: boolean;
  isValid: boolean;
  touched: boolean;
};

const sign = async (
  email: string,
  password: string,
  navigate: NavigateFunction,
  setCookie: (name: 'token', value: any, options?: any) => void,
  setError: (errorStatus: string) => void,
  setLoading: (isLoading: boolean) => void
) => {
  setError('');
  setLoading(true);
  const response = await signIn(email, password);

  if (response.idToken && response.userId) {
    const userInformation = await getUser(response.userId);

    localStorage.setItem('firstName', userInformation.first_name);
    localStorage.setItem('lastName', userInformation.last_name[0]);
    localStorage.setItem('userId', response.userId);
    localStorage.setItem('wins', userInformation.wins);

    navigate('/choose-avatar');

    setCookie('token', response.idToken, {
      maxAge: response.expiresIn,
    });
    setError('');
  } else {
    setError(response);
    setLoading(false);
  }
};

// enter key submit login
const useKey = (key: any, cb: (arg0: any) => void) => {
  const callbackRef = useRef(cb)

  useEffect(() => {
    callbackRef.current = cb;
  })

  function handle(event: { code: any; }) {
    if (event.code === key) {
      callbackRef.current(event)
    }
  }

  useEffect(() => {
    document.addEventListener("keypress", handle)
    return () => document.removeEventListener("keypress", handle)
  }, [key])
}


export const Login = (): JSX.Element => {
  const [cookie, setCookie] = useCookies(['token']);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusLogin, setStatusLogin] = useState(false);
  const [timeMessage, setTimeMessage] = useState("");

  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState<FieldValidation>({
    blur: false,
    isValid: false,
    touched: false,
  });
  const [password, setPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState<FieldValidation>({
    blur: false,
    isValid: false,
    touched: false,
  });

  const [isOpen, setOpen] = useState(false);


  const navigate = useNavigate();
  const tournamentStartTimeUTC = new Date('29 Jun 2022 19:00 UTC-4');
  const calculateTimeLeft = () => {
    let difference = +new Date(tournamentStartTimeUTC) - +new Date();

    let TWO_HOURS_IN_MILISSECONDS = 7200000

    let timeLeft: any = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };

      setTimeMessage(`Please, wait more ${timeLeft.days > 0 ? `${timeLeft.days} days` : ''} ${timeLeft.hours > 0 ? `${timeLeft.hours} hours` : ''} ${timeLeft.minutes > 0 ? `${timeLeft.minutes} minutes` : ''} ${timeLeft.seconds} seconds`)
      return true
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 }
      return false
    }
    return timeLeft;
  };

  const checkLogin = async () => {
    let status = calculateTimeLeft()
    console.log("enter key pressed")
    console.log(status)
    if (status) {
      setStatusLogin(true);
    } else {
      setStatusLogin(false);
      await sign(email, password, navigate, setCookie, setError, setLoading)
    }
  }

  // handleEnter call
  useKey("Enter", checkLogin);

  // Email validation
  const checkEmailValidation = (
    event: React.ChangeEvent<HTMLInputElement>,
    email: string
  ) => {
    const emailValue = event.target.value;
    setEmail(emailValue);

    const emailValid = !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{1,})$/i);

    setEmailValidation({
      ...emailValidation,
      touched: true,
      isValid: emailValid,
    });
  };

  const blurEmail = () => {
    setEmailValidation({
      ...emailValidation,
      blur: true,
    });
  };

  // Password validation
  const checkPasswordValidation = (
    event: React.ChangeEvent<HTMLInputElement>,
    password: string
  ) => {
    const pwValue = event.target.value;
    setPassword(pwValue);

    const passwordValid = pwValue.length >= 6;

    setPasswordValidation({
      ...passwordValidation,
      touched: true,
      isValid: passwordValid,
    });
  };

  const blurPassword = () => {
    setPasswordValidation({
      ...passwordValidation,
      blur: true,
    });
  };

  const closeModal = () => {
    setOpen(!isOpen);
  };

  return (
    <div className='card-login'>
      <Partners />
      <Card logo='color'>
        <h3>Tournament Login</h3>
        <form>
          <input
            className='input-field'
            type='text'
            placeholder='Email Address'
            value={email}
            onBlur={() => blurEmail()}
            onChange={(e) => checkEmailValidation(e, email)}
          />
          {/* {emailValidation.blur &&
          !emailValidation.isValid &&
          emailValidation.touched && (
            <div className='body1' style={{ color: 'red' }}>
              please enter the email you received your code at
            </div>
          )} */}

          <input
            className='input-field'
            type='text'
            placeholder='6 Digit Code'
            value={password}
            onBlur={() => blurPassword()}
            onChange={(e) => checkPasswordValidation(e, password)}

          />
          {passwordValidation.blur &&
            !passwordValidation.isValid &&
            passwordValidation.touched && (
              <div className='body1' style={{ color: 'red' }}>
                please enter the password you received
              </div>
            )}
          {statusLogin && (
            <div className='body1' style={{ color: 'red' }}>
              {timeMessage}
            </div>
          )}
        </form>
        <a className='pin-modal' onClick={() => setOpen(true)} style={{ marginTop: '20px' }}>Where's my code?</a>
        <Modal isVisible={isOpen} clicked={closeModal}>
          <Card logo=''>
            Please check your Email for your unique "Rock Paper Scissors Tournament Access Code" within the subject of the Email.
          </Card>
        </Modal>

        <Button
          clicked={checkLogin}
          text='Enter Tournament'
        />
        {error !== '' && <div className='error'>{error}</div>}
        {loading && (
          <div className='loading-modal loading-icon'>
            <Loading isLoading={loading} />
          </div>
        )}
      </Card>
    </div>
  );
};

export default Login;
