import { useEffect, useState } from 'react'
import { useInterval } from '../../../utils/interval'
import { enterMatchmaking, getUser, makeMove, pollMatchStatus, notifyWaiting, matchWon, pollPlayer, checkMatchStatus, } from '../../../utils/requests'

import './Queue.css'
import TournamentLogo from '../../../assets/tournament-logo.png'

import Lobby from '../../Lobby/Lobby'
import Overlay from '../loading/Overlay'
import MatchCard from '../MatchCard/MatchCard'
import GameLoading from '../../Game/GameLoading'
import GameMakeSelection from '../../Game/GameMakeSelection'
import GameShowSelection from '../../Game/GameShowSelection'
import GameShowResults from '../../Game/GameShowResults'
import GameEndMatch from '../../Game/GameEndMatch'

import one from '../../../assets/avatars/1.png'
import two from '../../../assets/avatars/2.png'
import three from '../../../assets/avatars/3.png'
import four from '../../../assets/avatars/4.png'
import five from '../../../assets/avatars/5.png'
import six from '../../../assets/avatars/6.png'
import seven from '../../../assets/avatars/7.png'
import eight from '../../../assets/avatars/8.png'
import nine from '../../../assets/avatars/9.png'
import ten from '../../../assets/avatars/10.png'
import eleven from '../../../assets/avatars/11.png'
import twelve from '../../../assets/avatars/12.png'
import thirteen from '../../../assets/avatars/13.png'
import fourteen from '../../../assets/avatars/14.png'
import fifteen from '../../../assets/avatars/15.png'
import sixteen from '../../../assets/avatars/16.png'
import seventeen from '../../../assets/avatars/17.png'
import eighteen from '../../../assets/avatars/18.png'
import { userInfo } from 'os'
import Modal from '../Modal/Modal'
import Card from '../Card/Card'
import { useNavigate } from 'react-router-dom'
import GameWinner from '../../Game/GameWinner'

const avatars: any = {
    '/static/media/1.2a93ee6ef2bd213a8dcd.png': one,
    '/static/media/2.04c3ef2a11c4aeb2e545.png': two,
    '/static/media/3.834fad9d8626768e8688.png': three,
    '/static/media/4.28c618fbfbbfbd7735f0.png': four,
    '/static/media/5.18e0cc3cd03edab147e4.png': five,
    '/static/media/6.af2c893ae0bc9503767f.png': six,
    '/static/media/7.67022a2cf06d01cbe4f0.png': seven,
    '/static/media/8.2bf535a004ac11e95139.png': eight,
    '/static/media/9.c858505c495030176c10.png': nine,
    '/static/media/10.c280082cca923b0659ab.png': ten,
    '/static/media/11.e208e8bc2d8af75f0954.png': eleven,
    '/static/media/12.00e2e16ed9a2d7c83d0d.png': twelve,
    '/static/media/13.f2fd87b5d85e8cdf4109.png': thirteen,
    '/static/media/14.2f2d4d4217ebfa878bbd.png': fourteen,
    '/static/media/15.fd54fb625e903835bee6.png': fifteen,
    '/static/media/16.4d5b3a8dd4c7fe9ec40d.png': sixteen,
    '/static/media/17.94ddeecc7ac5e315e3b0.png': seventeen,
    '/static/media/18.e6690c77f02cf7133392.png': eighteen
}


const countDownGame = async (startingValue: number, setNumber: (value: number) => void, startGame: (value: boolean) => void) => {
    if (startingValue >= 1) {
        setTimeout(() => {
            setNumber(startingValue - 1)
            if (startingValue - 1 === 0) startGame(true)
            countDownGame(startingValue - 1, setNumber, startGame)
        }, 1000)
    }
    return
}

const Queue = () => {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(3)
    const [match, setMatch] = useState<any>(undefined)
    const [matchId, setMatchId] = useState<any>(undefined)
    const [selectedMove, setSelectedMove] = useState('')
    const [updatedMove, setupdatedMove] = useState('')
    const [moveReceived, setMoveReceived] = useState('')
    const [loading, setLoading] = useState(false)
    const [otherPlayerId, setOtherPlayerId] = useState('')
    const [playerNumber, setPlayerNumber] = useState('')
    const [otherPlayerNumber, setOtherPlayerNumber] = useState('')
    const [wins, setWins] = useState(0)
    const [otherWins, setOtherWins] = useState(0)
    const [round, setRound] = useState(0)
    const [roundDate, setRoundDate] = useState(new Date())


    // Game States - Succedded - Waiting - Player X Wins - Waiting .. Player X Match
    const [gameTimer, setGameTimer] = useState(30);
    const [avatar, setAvatar] = useState<any>(undefined)
    const [otherAvatar, setOtherAvatar] = useState<any>(undefined)

    // Controllers
    const [isOpen, setOpen] = useState(false);
    const [otherPlayerLastId, setOtherPlayerLastId] = useState('')
    const [gameStart, startGame] = useState(false)
    const [waiting, setWaiting] = useState(false)
    const [gameDecided, setGameDecided] = useState(false)
    const [matchDecided, setMatchDecided] = useState(false)
    const [finalWinner, setFinalWinner] = useState(false)


    const playerName = `${localStorage.getItem('firstName')} ${localStorage
        .getItem('lastName')
        ?.charAt(0)}.`;
    const opponentName = `${localStorage.getItem('otherFirstName')} ${localStorage
        .getItem('otherLastName')
        ?.charAt(0)}.`;

    const sendMove = async (matchId: string, selectedMove: string, setLoading: (value: boolean) => void) => {
        setTimeout(async () => {
            await makeMove(matchId, `${localStorage.getItem('userId')}`, selectedMove)
            setSelectedMove(selectedMove)
            const pollMatch = await pollMatchStatus(`${localStorage.getItem('userId')}`)
            setMatch(pollMatch.match)
            setLoading(false)
        }, 1000)
    }

    const checkQuitter = async () => {
        if (selectedMove == "") return;
        await makeMove(match.id, `${localStorage.getItem('otherUserId')}`, "Quit")
    }

    const quit = async () => {
        await makeMove(match.id, `${localStorage.getItem('userId')}`, "Quit")
        navigate("/result")
    }


    const updateMove = async (option: string) => {
        setLoading(true)
        await sendMove(match.id, option, setLoading)
    };

    const checkBugResult = async () => {
        setLoading(true)
        await checkMatchStatus(match.id, `${localStorage.getItem('userId')}`, "Rock")
    };

    const createTimer = () => {
        if (match) {
            let timeStamp: any = new Date()
            let roundTimer: any = new Date(match.time_stamp)

            let time = 35 - new Date(timeStamp - roundTimer).getSeconds()
            // Run when countdown hits 0 and send players move to none
            if (!matchDecided || !gameDecided) {
                if (time <= 0) {
                    if (time <= -15) checkQuitter();
                    if(time <= -2) {
                        if (selectedMove === "" || selectedMove === "None") updateMove("None");
                    }
                    time = 0;
                }
            }
            setGameTimer(time)
        } else {
            setGameTimer(30)
        }
    }

    const updatePlayersInformation = (playerNumber: string) => {
        setRound(match.game_count)
        if (playerNumber === 'Player 1') {
            setWins(match.player_1_wins)
            setOtherWins(match.player_2_wins)
            setSelectedMove(match.player_1_last_move)
            setupdatedMove(match.player_1_last_move)
            setMoveReceived(match.player_2_last_move)
        } else {
            setWins(match.player_2_wins)
            setOtherWins(match.player_1_wins)
            setSelectedMove(match.player_2_last_move)
            setupdatedMove(match.player_2_last_move)
            setMoveReceived(match.player_1_last_move)
        }
    }

    const closeModal = () => {
        setOpen(!isOpen);
    };

    useInterval(async () => {
        createTimer()
    }, 1000)

    // This function run once we are in lobby and makes us enter a match
    // useEffect(() => {
    //     async function enter() {
    //         await enterMatchmaking(`${localStorage.getItem('userId')}`)
    //     }
    //     enter()
    // }, [])

    // function runs every half of second and query the backend to see if the match
    useInterval(async () => {
        // let remainingPlayers: any = localStorage.getItem("remaining");
        // let playerWins: any = localStorage.getItem("wins");
        // let contention: any = localStorage.getItem("contention");
        // if (remainingPlayers == 1 && playerWins > 0 && contention == 'true') {
        //     navigate("/winner")
        // }
        const pollMatch = await pollMatchStatus(`${localStorage.getItem('userId')}`)
        if (pollMatch.match) {
            if (!match) {
                countDownGame(timer, setTimer, startGame)
            }
            setMatch(pollMatch.match)
        }
    }, 1000)

    // function runs everytime we get a new oponent and updates its values
    useEffect(() => {
        const getOtherUser = async () => {
            const otherUser = await getUser(otherPlayerId)
            if (otherUser) {
                localStorage.setItem('otherWins', otherUser.wins)
                localStorage.setItem('otherFirstName', otherUser.first_name)
                localStorage.setItem('otherLastName', otherUser.last_name[0])
                localStorage.setItem('otherUserId', otherPlayerId)
            }
        }
        if (otherPlayerId !== otherPlayerLastId) {
            setOtherPlayerLastId(otherPlayerId)
            getOtherUser()
        }
    }, [otherPlayerId])

    useEffect(() => {
        async function pollPlayerStats() {
            const playerAvatar = await pollPlayer(`${localStorage.getItem('userId')}`)
            const otherPlayerAvatar = await pollPlayer(`${localStorage.getItem('otherUserId')}`)
            if (playerAvatar.player.avatar && otherPlayerAvatar.player.avatar) {
                setAvatar(playerAvatar.player.avatar)
                setOtherAvatar(otherPlayerAvatar.player.avatar)
            }
        }
        pollPlayerStats()
    }, [otherPlayerLastId])

    // This function will run everytime match changes, and updates players status.
    useEffect(() => {
        if (match && !matchDecided) {
            setMatchId(match.id)
            const matchState = match.match_status;
            const getOtherPlayerId = match.player_1_id === `${localStorage.getItem('userId')}` ? match.player_2_id : match.player_1_id
            const playerNumber = match.player_1_id === `${localStorage.getItem('userId')}` ? 'Player 1' : 'Player 2'
            setPlayerNumber(playerNumber)
            setOtherPlayerNumber(playerNumber === 'Player 1' ? 'Player 2' : 'Player 1')
            setOtherPlayerId(getOtherPlayerId)
            updatePlayersInformation(playerNumber)


            if (matchState === `Player 1 Wins Match` || matchState === `Player 2 Wins Match`) {
                setTimeout(() => {
                    setMatchDecided(true)
                }, 2000);
                return;
            }
            if (matchState === `${playerNumber} Wins Game` || matchState === `${otherPlayerNumber} Wins Game` || matchState === 'Game Tie') {
                setLoading(false)
                setTimeout(() => {
                    setGameDecided(true)
                    notifyWaiting(match.id, `${localStorage.getItem('userId')}`)
                }, 2000)

                if (matchState === 'Game Tie') {
                    setTimeout(() => {
                        setRoundDate(match.time_stamp)
                    }, 3000);
                }
                return;
            }
            else if (match.match_status === `Player 1 Wins Match` || match.match_status === `Player 2 Wins Match`) {
                setMatchDecided(true)
            }
            else if (matchState === 'Waiting') {
                if (moveReceived !== "" && selectedMove !== "") {
                    console.log("thats the case")
                    // TODO call checkGameResult
                    checkBugResult()
                }
                setGameDecided(false)
                setWaiting(true)
                setLoading(false)
                return
            }
            else if (matchState === `${otherPlayerNumber} Waiting`) {
                setLoading(true)
                notifyWaiting(match.id, `${localStorage.getItem('userId')}`)
                return
            }
            else if (matchState === `${playerNumber} Waiting`) {
                setLoading(true)
                return
            }
            return
        }
    }, [match])

    useEffect(() => {
        if (match) {
            let playerNumber = match.player_1_id === `${localStorage.getItem('userId')}` ? 'Player 1 Wins Match' : 'Player 2 Wins Match'
            if (matchDecided && match.match_status === playerNumber) {
                let winner: any
                let loser: any

                if (match.player_1_wins === 3) {
                    winner = match.player_1_id
                    loser = match.player_2_id
                } else {
                    winner = match.player_2_id
                    loser = match.player_1_id
                }
                matchWon(winner, loser, match.id)
            }
        }
    }, [matchDecided])

    return (
        <div className='queue' style={{ backgroundColor: match ? ((match.match_status === `${playerNumber} Wins Game` || match.match_status === `${playerNumber} Wins Match`) ? '#007e41' : (match.match_status === 'Game Tie' ? '#953E00' : ((match.match_status === `${otherPlayerNumber} Wins Match` || match.match_status === `${otherPlayerNumber} Wins Game`) ? '#a52d33' : '#673B90'))) : '#054f95' }}>
            <Overlay isLoading={loading} />
            {!match && !waiting ?
                <Lobby /> :
                <div className='game-start' >
                    <div className='game-loading' style={{ opacity: gameStart ? '0' : (matchDecided ? '0' : '1'), display: gameStart ? 'none' : (matchDecided ? 'none' : 'unset') }}>
                        <GameLoading timer={timer} />
                    </div>
                    <div className='game-started' style={{ opacity: (gameStart || (!gameStart && matchDecided)) ? '1' : '0' }}>
                        <div className='game-counter'>Round {round + 1}</div>
                        <div className='game-score'>
                            <MatchCard cardFor={playerName} avatar={avatars[avatar]} winCount={wins} />
                            <div className='user-vs-text'>VS</div>
                            <MatchCard cardFor={opponentName} avatar={avatars[otherAvatar]} winCount={otherWins} />
                        </div>
                        {
                            (!gameDecided && !matchDecided) ?
                                updatedMove === '' ?
                                    <GameMakeSelection setSelectedMove={updateMove} />
                                    :
                                    <GameShowSelection playerSelection={selectedMove} opponentName={opponentName + " pick"} opponentSelection={moveReceived} />
                                : <></>
                        }
                        {
                            !gameDecided && !matchDecided && moveReceived === "" && <div className='countdown-circle game-timer'>{gameTimer}</div>
                        }
                        {gameDecided && match.match_status === 'Game Tie' &&
                            <GameShowResults selectedMove={selectedMove} resultText={'tie'} />
                        }
                        {gameDecided && match.match_status === `${playerNumber} Wins Game` &&
                            <GameShowResults selectedMove={selectedMove} resultText={'won'} />
                        }
                        {gameDecided && match.match_status === `${otherPlayerNumber} Wins Game` &&
                            <GameShowResults selectedMove={moveReceived} resultText={'lost'} />
                        }
                        {
                            matchDecided ?
                                match.match_status === `${playerNumber} Wins Match` ?
                                    <GameEndMatch result='won' /> : <GameEndMatch result='lost' /> : <></>
                        }
                    </div>
                </div>}
            <div>
                <div className='exit-btn' onClick={() => setOpen(true)}>
                    <button>X</button>
                </div>
                <Modal isVisible={isOpen} clicked={closeModal}>
                    <Card logo=''>
                        <p>Are you sure you want to quit?</p>
                        <div className="btn-div">
                            <button className='quit-btn' onClick={quit}>Yes</button>
                            <button className='cancel-btn' onClick={closeModal}>Cancel</button>
                        </div>
                    </Card>
                </Modal>
            </div>
        </div>
    )
}

export default Queue