import colorLogo from "../../../assets/tournament_banner.jpeg";
import whiteLogo from "../../../assets/tournament_banner_white.png";

import "./Card.css";

interface CardProps {
  children: any;
  logo: "white" | "color" | '';
}

// todo: figure out what the actual property type this is
export const Card = ({ children, logo }: CardProps): JSX.Element => {
  return (
    <div>
      {logo === "white" && <img className='white-logo' src={whiteLogo} alt="Tournament Rock Paper Scissors"/>}
      <div className='Card'>
        {logo === "color" && <img className='color-logo' src={colorLogo} alt="Tournament Rock Paper Scissors"/>}
        {children}
      </div>
    </div>
  );
};

export default Card;
