import { createContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ChooseAvatar from './components/ChooseAvatar/ChooseAvatar';
import Countdown from './components/Countdown/Countdown';
// import Game from './components/Game/Game';
import HowTo from './components/HowTo/HowTo';
import Lobby from './components/Lobby/Lobby';
import Login from './components/Login/Login';
import Queue from './components/ui/queue/Queue';

// test icons. Not sure if they're actually copyright free, please replace
import rock from './assets/game_assets/rock.png';
import paper from './assets/game_assets/paper.png';
import scissors from './assets/game_assets/scissors.png';

import './App.css';
import Leaderboard from './components/ui/Leaderboard/Leaderboard';
import GameWinner from './components/Game/GameWinner';
import GameController from './components/Game/GameController';
import GameEndMatch from './components/Game/GameEndMatch';

const sampling = (array: Array<string>): string => {
  return array[Math.floor(Math.random() * array.length)];
};

// This needs to be replaced with the sw3 call to get the bucket
const avatarsAvailable = [rock, paper, scissors, scissors, paper, rock];
export const avatarsContext = createContext({
  avatarsAvailable: [''],
  sampleOne: sampling,
});
export const avatars = {
  avatarsAvailable: avatarsAvailable,
  sampleOne: sampling,
};

// This context can be used to replace all calls to local storage for player info

const modifyUser = (name: string, email: string, avatar: string) => {
  user.name = name;
  user.email = email;
  user.avatar = avatar;
};

export const UserContext = createContext({
  name: '',
  email: '',
  avatar: '',
  setUser: modifyUser,
});

const user = {
  name: 'Shaunslk P',
  email: 'Shaun.p@gmail.com',
  avatar: sampling(avatarsAvailable),
  setUser: modifyUser,
};

const App = (): JSX.Element => {
  // Can we use this to change the bg-color for the game rather than superimposing from the Queue page?
  // document.body.style.backgroundColor = 'green';

  return (
    <UserContext.Provider value={user}>
      <avatarsContext.Provider value={avatars}>
        <BrowserRouter>
          <div className='App'>
            <Routes>
              <Route path='/' element={<Countdown />} />
              <Route path='/choose-avatar' element={<ChooseAvatar />} />
              {/* <Route path='/game' element={<Game />} /> */}
              <Route path='/how-to' element={<HowTo />} />
              <Route path='/lobby' element={<Lobby />} />
              <Route path='/login' element={<Login />} />
              <Route path={`/game/:id`} element={<GameController />} />
              <Route path={`/gameResult/:id/:results`} element={<GameEndMatch result='won'/>} />
              <Route path='/queue' element={<Queue />} />
              <Route path='/result' element={<Leaderboard />} />
              <Route path='/winner' element={<GameWinner />} />
            </Routes>
          </div>
        </BrowserRouter>
      </avatarsContext.Provider>
    </UserContext.Provider>
  );
};

export default App;
