import { useContext, useState, useEffect } from 'react';
import './MatchCard.css';
import { UserContext } from '../../../App';
import score from '../../../assets/game_assets/score.svg';


type MatchCardProps = {
  cardFor: string;
  avatar: any;
  winCount: number;
};

/* Display a MatchCard that has 3 circles indicating how many wins/losses so far */
const MatchCard = ({ cardFor, avatar, winCount }: MatchCardProps) => {

  const [avatarIcon, setAvatarIcon] = useState<any>(undefined)

  const user = useContext(UserContext);
  
  if(avatar === "user") {
    avatar = localStorage.getItem("avatar")
  }

 

  return (

    <div className='user-score'>
      <div className='user-name-section'>
        <img className='player-picture' src={avatar} alt="User Avatar" />

        <div className='user-name'>{cardFor}</div>
      </div>

      <div className='user-wins'>
        <div className='user-wins-text'>Wins:</div>
        {
          winCount >= 1 ?
            <img className='player-score' src={score} alt="User Avatar" />
            :
            <div
              className='user-wins-circle'
              style={{
                backgroundColor: winCount >= 1 ? '#007e41' : '#c4c4c4',
              }}
            />
        }
        {
          winCount >= 2 ?
            <img className='player-score' src={score} alt="User Avatar" />
            :
            <div
              className='user-wins-circle'
              style={{
                backgroundColor: winCount >= 2 ? '#007e41' : '#c4c4c4',
              }}
            />
        }
        {
          winCount >= 3 ?
            <img className='player-score' src={score} alt="User Avatar" />
            :
            <div
              className='user-wins-circle'
              style={{
                backgroundColor: winCount >= 3 ? '#007e41' : '#c4c4c4',
              }}
            />
        }
      </div>
    </div>

  );
};

export default MatchCard;
