import './Modal.css';

interface Props {

    isVisible: boolean,

    clicked: () => void
}

const Modal: React.FC<Props> = ({ isVisible, clicked, children }) => {
  if (isVisible) {
    return (
      <div className='modal' onClick={clicked}>
        <div className='modal-body'>
          {children}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Modal;