
import './Partners.css';
import p1 from '../../../assets/partners/JPM_banner.png'
import p2 from '../../../assets/partners/Post-script-banner.png'


const Partners = () => {
    return (
        <div className="partner-container">
            <img src={p1} alt="" />
            <img src={p2} alt="" />
        </div>
    );
};

export default Partners;
