
import { ReactComponent as Spinner } from '../../../assets/loading.svg'
import './Loading.css'

interface Props {
    isLoading: boolean
}

const Loading : React.FC<Props> = ({ isLoading }) => {
    return (
        isLoading ? <div className='loading-circle'>
            <div className='spinner-container'>
                <Spinner/>
            </div>
        </div> : null
    )
}

export default Loading