import './GameEndMatch.css';
import { ReactComponent as Crown } from '../../assets/crown.svg'
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useInterval } from '../../utils/interval';
import RoundCountDown from './GameUtils/RoundCountDown';

type GameEndMatchProps = {
    result: string;
};

const GameEndMatch = ({
    result,
}: GameEndMatchProps) => {

    const [gameTimer, setGameTimer] = useState(5);

    const createTimer = () => {
        setGameTimer(gameTimer - 1)
    }

    useInterval(async () => {
        createTimer()
    }, 1000)

    const navigate = useNavigate();
    const { id, results } = useParams()
    result = results === "w" ? "won" : "lost"
    localStorage.setItem('otherWins', '')
    localStorage.setItem('otherFirstName', '')
    localStorage.setItem('otherLastName', '')
    localStorage.setItem('otherUserId', '')
    if (result === 'won') {
        setTimeout(() => {
            navigate("/lobby")
        }, 5000)
    }
    return (
        <div className='queue' style={{ backgroundColor: result ? ((result === `won`) ? '#007e41' : ((result === `lost` ? '#a52d33' : '#673B90'))) : '#054f95' }}>
            <div className='game-start' >
            {result === 'won' ? <RoundCountDown gameTimer={gameTimer} /> : <></>}
                <div className='game-started'>
                    <div className='game-selection' style={{ marginTop: '35px' }}>
                        {result === 'won' ? <Crown /> : <></>}
                        {result === 'won' ?
                            <div className='game-selection-headline'>
                                You {result} the match!
                            </div>
                            :
                            <div className='game-selection-headline'>
                                Nice try!
                            </div>
                        }

                        {result === 'won' ?
                            <>
                                <div className='game-selection-subtitle'>
                                    Congrats! On to the next round!
                                </div>
                                <div className='go-to-button' onClick={() => navigate("/lobby")}>
                                    Play Next Game
                                </div>
                            </>
                            :
                            <>
                                <div className='game-selection-subtitle'>
                                    Better luck next time, thanks for playing!
                                </div>
                                <div className='go-to-button' onClick={() => navigate("/lobby")}>
                                    Play for fun
                                </div>
                                <div className='go-to-button' onClick={() => navigate("/result")}>
                                    Quit
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameEndMatch;
